<!--
 * @Descripttion: 子账号详情
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-02 17:24:10
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-08-18 14:36:17
-->
<template>
    <div class="accountDataInfo" ref="accountDataInfo">
        <div class="title-wrap">
            <div class="tag"></div>
            <el-breadcrumb separator-class="el-icon-arrow-right">
                <el-breadcrumb-item :to="{ name: 'accountData' }">账号数据</el-breadcrumb-item>
                <el-breadcrumb-item>账号详情<span>({{username}})</span></el-breadcrumb-item>
            </el-breadcrumb>
        </div>

        <div class="case-wrap tabs-wrap">
            <el-tabs v-model="activeName" type="card">
                <el-tab-pane label="账号详情" name="first">
                    <accountDetails ref="accountDetails"></accountDetails>
                </el-tab-pane>
                <el-tab-pane label="跟进记录" name="second">
                    <followRecords ref="followRecords"></followRecords>
                </el-tab-pane>
                <el-tab-pane label="数据分析" name="thirdly">
                    <dataAnalysis ref="dataAnalysis"></dataAnalysis>
                </el-tab-pane>
                <el-tab-pane label="功能分析" name="fourthly">
                    <functionalAnalysis ref="functionalAnalysis"></functionalAnalysis>
                </el-tab-pane>
            </el-tabs>
        </div>
    </div>
</template>

<script>
import accountDetails from './accountComponents/accountDetails'
import followRecords from './accountComponents/followRecords'
import dataAnalysis from './accountComponents/dataAnalysis'
import functionalAnalysis from './accountComponents/functionalAnalysis'
export default {
    components:{
        accountDetails,
        followRecords,
        dataAnalysis,
        functionalAnalysis
    },
    data () {
        return {
            activeName: 'first',
            userInfo: {},
            type: '',
            username: ''
        }
    },
    mounted () {
        this.type = this.$route.query.type
        if(this.type){
            this.activeName = this.type;
        }
        this.userInfo = JSON.parse(localStorage.getItem('userInfo'));
        let info = JSON.parse(localStorage.getItem('account'));
        if(info.username){
            this.username = info.username
        }else{
            this.username = info.user.username
        }
        let width =  this.$refs.accountDataInfo.offsetWidth - 400;
        let widths =  this.$refs.accountDataInfo.offsetWidth - 100;
        this.$refs.dataAnalysis.setValue(width);
        this.$refs.functionalAnalysis.setValue(widths)
    },
    methods: {

    },
}
</script>

<style lang='scss'>
.accountDataInfo{
    background: rgb(240, 242, 245);
    padding: 10px 25px;
    height: calc(100vh - 80px);
    overflow-y: auto;
    .title-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 5px 0;
        .tag{
            width: 4px;
            height: 15px;
            background: #409EFF;
            border-radius: 10px;
            margin-right: 10px;
        }
        .title{
            font-size: 18px;
            font-weight: bold;
            padding-left: 10px;
        }
    }
    .tabs-wrap{
        background: #fff;
        margin-top: 20px;
        border-radius: 6px;
        .el-tabs__header{
            padding-top: 20px;
            margin: 0 20px;
        }
    }
    .el-breadcrumb__item{
        .el-breadcrumb__inner{
            color: #000;
            font-weight: bold;
        }
        .is-link{
            color: #333;
            font-weight: 300;
        }
    }
}
</style>
