<template>
    <div class="accountDetails">
        <div class="box-wrap" v-loading="loading">
            <el-descriptions :column="1" size="medium" border
                labelClassName="labelClassName" contentClassName="contentClassName">
                <el-descriptions-item label="企业名称">{{info.enterprise != null?info.enterprise.name:''}}</el-descriptions-item>
                <el-descriptions-item label="品牌名称">{{info.enterprise != null?info.enterprise_brand.name:''}}</el-descriptions-item>
                <el-descriptions-item label="门店名称">{{info.shop != null?info.shop.name:''}}</el-descriptions-item>
                <el-descriptions-item label="合约到期日期">{{info.shop != null?info.shop.contract_end_time:''}}</el-descriptions-item>
                <el-descriptions-item label="门店地址">{{info.shop != null?info.shop.address:''}}</el-descriptions-item>
                <el-descriptions-item label="账号头像">
                    <div class="image-wrap">
                        <img @click="clickImage(info.head)" :src="info.head" class="pic" />
                    </div>
                </el-descriptions-item>
                <el-descriptions-item label="账号名称">{{info.username}}</el-descriptions-item>
                <el-descriptions-item label="联系电话">{{info.mobile}}</el-descriptions-item>
                <el-descriptions-item label="角色">{{info.duties_name}}</el-descriptions-item>
                <el-descriptions-item label="本月粘度状态">{{info.viscosity}}</el-descriptions-item>
                <el-descriptions-item label="最后登陆时间">{{info.last_daily_active}}</el-descriptions-item>
                <el-descriptions-item label="创建时间">{{info.create_timex}}</el-descriptions-item>
                <el-descriptions-item label="客服负责人">{{info.customer_server_name}}</el-descriptions-item>
            </el-descriptions>
            <div v-if="isShowImages">
                <el-image-viewer 
                :on-close="closeViewer" 
                :url-list="imageList" />
            </div>
        </div>
    </div>
</template>

<script>
import common from "@/utils/common.js"
import ElImageViewer from 'element-ui/packages/image/src/image-viewer'
export default {
    components: {
      ElImageViewer,
    },
    data () {
        return {
            shopId: 0,
            info: {},
            isShowImages: false,
            imageList: [],
            loading: false
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('account'))
        if(info.user_id){
            this.shopId = info.user_id
        }else{
            this.shopId = info.id
        }
        this.getBaseShow();
    },
    methods: {
        clickImage(item){
            this.isShowImages = true;
            this.imageList.push(item);
        },
        // 关闭查看器
        closeViewer() {
            this.isShowImages = false;
            this.imageList = [];
        },
        getBaseShow(){
            let params = {
                id: this.shopId
            }
            this.loading = true;
            common.connect("/customerservicev1/user/show",params,(res)=>{
                this.info = res.data;
                this.loading = false;
            });
        }
    },
}
</script>

<style lang='scss'>
.accountDetails{
    padding: 20px;
    .box-wrap{
        min-height: 400px;
    }
    .labelClassName{
        width: 120px;
        font-size: 15px;
        color: #000;
        line-height: 40px;
    }
    .contentClassName{
        font-size: 15px;
        color: #666;
        margin-left: 20px;
        line-height: 40px;
        width: calc(100% - 105px);
    }
    .image-wrap{
        width: 100%;
        .pic{
            width: 100px;
            cursor: pointer;
        }
    }
    .content{
        line-height: 34px;
        margin-top: -15px;
    }
}
</style>
