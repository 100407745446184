<!--
 * @Descripttion: 功能分析
 * @version: 
 * @Author: alex.yang
 * @Date: 2022-08-08 15:32:43
 * @LastEditors: alex.yang
 * @LastEditTime: 2022-12-01 10:27:30
-->
<template>
    <div class="functionalAnalysis">
        <div class="case-wrap">
            <commonSelect style="padding-bottom: 20px"
                @changeDate="changeDate"
                @clickTagDate="clickTagDate"
            ></commonSelect>
            <el-descriptions :column="1" size="medium" border  v-loading="loading"
                labelClassName="labelClassName" contentClassName="contentClassName">
                <!-- <el-descriptions-item label="门店类型">{{info.label_1 != null?info.label_1:''}}</el-descriptions-item>
                <el-descriptions-item label="团队人数">{{info.label_2 != null?info.label_2:''}}</el-descriptions-item>
                <el-descriptions-item label="用户角色">{{info.label_0 != null?info.label_0:''}}</el-descriptions-item>
                <el-descriptions-item label="绩效功能情况">
                    <div class="use-wrap">
                        <div class="item" v-for="(item,index) in info.function" :key="index"
                            :class="item.is_done == 1?'active':''">
                            <i v-if="item.is_done == 0" class="iconfont el-icon-circle-check"></i>
                            <i v-else class="icon el-icon-success"></i>
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </el-descriptions-item> -->
                <el-descriptions-item label="其他功能情况">
                    <div class="use-wrap">
                        <div class="item" v-for="(item,index) in info.inessential_function" :key="index"
                            :class="item.is_done == 1?'active':''">
                            <i v-if="item.is_done == 0" class="iconfont el-icon-circle-check"></i>
                            <i v-else class="icon el-icon-success"></i>
                            <div>{{item.name}}</div>
                        </div>
                    </div>
                </el-descriptions-item>
            </el-descriptions>
        </div>
        <div class="data-wrap" v-loading="loading1">
            <div class="title-wrap">
                <div class="tag"></div>
                <div class="title">功能使用分析</div>
            </div>
            <div id="functionGrades" :style="{width: width+'px'}" style="height: 350px;"></div>
        </div>
    </div>
</template>

<script>
import {functionDataLineEcharts} from "@/echarts/lineEchartData";
import commonSelect from "@/components/commonSelect.vue"
import common from "@/utils/common.js"
export default {
    components:{
        commonSelect
    },
    data () {
        return {
            user_id: '',
            loading: false,
            loading1: false,
            info: {},
            dateType: '',
            startDate: '',
            endDate: '',
            width: 0
        }
    },
    mounted () {
        let info = JSON.parse(localStorage.getItem('account'))
        if(info.user_id){
            this.user_id = info.user_id
        }else{
            this.user_id = info.id
        }
        this.getUseFunction();
        this.getUseFunctionChart();
    },
    methods: {
        setValue(value){
            this.width = value
        },
        getUseFunctionChart(){
            let params = {
                user_id: this.user_id,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate
            }
            this.loading1 = true;
            common.connect("/customerservicev1/user/useFunctionLineChart",params,(res)=>{
                functionDataLineEcharts('functionGrades',res.data)
                this.loading1 = false;
           });
        },
        // 日期选择
        changeDate(startDate,endDate){
            this.currentPage = 1;
            this.startDate = startDate.slice(0,11)+' 00:00:01';
            this.endDate = endDate.slice(0,11)+' 23:59:59';
            this.getUseFunction();
            this.getUseFunctionChart()
        },
        clickTagDate(item){
            this.dateType = item.label;
            this.currentPage = 1;
            if(item.label != 'date'){
                this.startDate = '';
                this.endDate = '';
                this.getUseFunction();
                this.getUseFunctionChart();
            }
        },
        getUseFunction(){
            let params = {
                user_id: this.user_id,
                date_type: this.dateType,
                start_date: this.startDate,
                end_date: this.endDate,
            }
            this.loading = true;
            common.connect("/customerservicev1/user/useFunction",params,(res)=>{
                this.info = res.data;
                this.loading = false;
            });
        }
    },
}
</script>

<style lang='scss'>
.functionalAnalysis{
    background: rgb(240, 242, 245);
    .case-wrap{
        background: #fff;
        padding: 20px;
    }
    .labelClassName{
        width: 120px;
        font-size: 15px;
        color: #000;
        line-height: 40px;
    }
    .contentClassName{
        font-size: 15px;
        color: #666;
        margin-left: 20px;
        line-height: 40px;
        width: calc(100% - 105px);
    }
    .use-wrap{
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        .item{
            display: flex;
            justify-content: center;
            align-items: center;
            background: #eee;
            padding: 5px 10px;
            border-radius: 6px;
            color: #999;
            margin-left: 10px;
            margin-bottom: 10px;
            .iconfont{
                font-size: 17px;
                margin-right: 5px;
                color: #999;
            }
            .icon{
                font-size: 17px;
                margin-right: 5px;
                color: #fff;
            }
        }
        .active{
            background: #409EFF;
            color: #fff;
        }
    }
    .data-wrap{
        padding: 0 20px;
        border-radius: 6px;
        overflow: hidden;
        background: #fff;
        margin: 20px 0;
        .title-wrap{
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            margin-top: 20px;
            .tag{
                width: 3px;
                height: 15px;
                background: #409EFF;
                border-radius: 10px;
            }
            .title{
                font-size: 15px;
                font-weight: bold;
                padding-left: 10px;
            }
        }
    }
}
</style>
